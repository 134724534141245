import { StencilProvider } from '@amzn/stencil-react-components/context';
import defaultTheme from '@amzn/stencil-react-theme-default';
import { STENCIL_FONT_SIZE } from './styles';
import React, { ReactNode } from 'react';

export interface IStencilProviderProps {
  children: ReactNode;
}
const StencilProviderWrapper = ({ children }: IStencilProviderProps) => {
  return (
    <StencilProvider
      theme={{
        ...defaultTheme,
        font: {
          ...defaultTheme.font,
          monospace: { ...defaultTheme.font.monospace, size: STENCIL_FONT_SIZE },
          primary: { ...defaultTheme.font.primary, size: STENCIL_FONT_SIZE },
        },
      }}
    >
      {children}
    </StencilProvider>
  );
};

export default StencilProviderWrapper;
