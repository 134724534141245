import React from 'react';
import StencilProviderWrapper from './StencilProviderWrapper';
import {HomePage} from './HomePage';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {HomePageUnauthorised} from "src/components/HomePageUnauthorised";
import { GlobalStyles } from './GlobalStyles';

export class App extends React.Component {
    render() {
        return (
            <StencilProviderWrapper>
                <GlobalStyles/>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/unauthorised">
                            {<HomePageUnauthorised/>}
                        </Route>
                        <Route exact path="/">
                            {<HomePage/>}
                        </Route>
                    </Switch>
                </BrowserRouter>
            </StencilProviderWrapper>
        );
    }
}

export default App;
