import 'reset-css';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './logger';

import './index.scss';

ReactDOM.render(
    <React.StrictMode>
        <AppInitWrapper>
            <React.Suspense fallback={<></>}>
                <App />
            </React.Suspense>
        </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);
