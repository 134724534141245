const stage: string = "beta";

const LiteURLStageMap:  Record<string, string> = {
    prod : 'https://lite.myhr.a2z.com/',
    beta : 'https://lite-myhr.pxt.amazon.dev/',
    alpha: 'https://alpha.lite-myhr.pxt.amazon.dev/',
    test: 'https://alpha.lite-myhr.pxt.amazon.dev/',
}

export enum LoginMethod {
    MAASK,
    ATOZ
} ;

const RedirectionURLStageMap: Record<LoginMethod, Record<string, string>> = {
    [LoginMethod.MAASK]:{
        prod : 'https://idp.amazon.work/idp/delegate/maask?redirect=https://atoz.amazon.work/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
        beta : 'https://idp-ext-gamma.amazon.work/idp/delegate/maask?redirect=https://atoz.integ.amazon.com/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
        alpha: 'https://idp-ext-gamma.amazon.work/idp/delegate/maask?redirect=https://atoz.integ.amazon.com/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
        test : 'https://idp-ext-gamma.amazon.work/idp/delegate/maask?redirect=https://atoz.integ.amazon.com/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
    },
    [LoginMethod.ATOZ]:{
        prod : 'https://atoz.amazon.work/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
        beta : 'https://atoz.integ.amazon.com/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
        alpha: 'https://atoz.integ.amazon.com/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
        test : 'https://atoz.integ.amazon.com/myhr?myhr-lite-clientId=MyHR_Lite&deviceType=kiosk',
    },

  
}
 
export const getEnvironmentBasedURL = (fileName: string) => {
    return LiteURLStageMap[stage]+fileName+'.png';
}


export const getEnvironmentBasedRedirectionURL = (loginMethod: LoginMethod) => {
    return RedirectionURLStageMap[loginMethod][stage];
}