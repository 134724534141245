export const STENCIL_FONT_SIZE = {
    T600: {
      xl: { fontSize: '25px', lineHeight: 1.3 },
      l: { fontSize: '22.5px', lineHeight: 1.3333 },
      m: { fontSize: '20px', lineHeight: 1.375 },
      s: { fontSize: '17.5px', lineHeight: 1.35714 },
    },
    T500: {
      xl: { fontSize: '20px', lineHeight: 1.375 },
      l: { fontSize: '17.5px', lineHeight: 1.42857 },
      m: { fontSize: '16.25px', lineHeight: 1.46154 },
      s: { fontSize: '13.75px', lineHeight: 1.45455 },
    },
    T400: {
      xl: { fontSize: '15px', lineHeight: 1.5 },
      l: { fontSize: '13.75px', lineHeight: 1.54545 },
      m: { fontSize: '12.5px', lineHeight: 1.4 },
      s: { fontSize: '11.25px', lineHeight: 1.55556 },
    },
    T300: {
      xl: { fontSize: '11.25px', lineHeight: 1.55556 },
      l: { fontSize: '11.25px', lineHeight: 1.55556 },
      m: { fontSize: '10px', lineHeight: 1.5 },
      s: { fontSize: '10px', lineHeight: 1.5 },
    },
    T200: {
      xl: { fontSize: '10px', lineHeight: 1.5 },
      l: { fontSize: '10px', lineHeight: 1.5 },
      m: { fontSize: '8.75px', lineHeight: 1.57143 },
      s: { fontSize: '8.75px', lineHeight: 1.57143 },
    },
    T100: {
      xl: { fontSize: '8.75px', lineHeight: 1.57143 },
      l: { fontSize: '8.75px', lineHeight: 1.57143 },
      m: { fontSize: '7.5px', lineHeight: 1.66667 },
      s: { fontSize: '7.5px', lineHeight: 1.66667 },
    },
    T50: {
      xl: { fontSize: '7.5px', lineHeight: 1.66667 },
      l: { fontSize: '6.25px', lineHeight: 1.8 },
      m: { fontSize: '5px', lineHeight: 1.8 },
      s: { fontSize: '3.75px', lineHeight: 1.8 },
    }
  };
  