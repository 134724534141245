import React from 'react';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import { Text } from '@amzn/stencil-react-components/text';
import { Card, LinkCard } from '@amzn/stencil-react-components/card';
import { getEnvironmentBasedRedirectionURL, getEnvironmentBasedURL, LoginMethod } from './Utils';
import { AtoZMyHRLogo, LeftImage, RightImage } from '../images/logos';
import './HomePage.css';
import { IconChevronRight } from '@amzn/stencil-react-components/dist/submodules/icons';

export const HomePage = () => {
    const { matches } = useBreakpoints();
    const isMobile = matches.s || matches.m;
    document.title = "My HR Lite Welcome Page";
    
    // Read query parameters from URL
    const urlParams = new URLSearchParams(window.location.search);
    const siteCode = urlParams?.get('site_code');
    const isMaask = urlParams?.get('maask') === 'true';

    /* TODO: 
    1. remove below completely as we dont want to auto close this window on kiosks where authentication is done through MAASK and in browser
    2. change required to render this page again on logout, either manual logout from atoz nav bar or logout due to timeout set on myh (kiosk) app
    3. add metrics 
        - count metric on page render on kiosk boot up 
        - count metric on page render after logout/timeout redirect,
        - count metric on click of login button
        - count metric on successful login
        - count metric on failed login
        - count metric on successful logout
        - count metric on failed logout
        - latency metric on page load
        - add error boundary on top of app and emit error metric
    */
  return (
    <View >
        <Row className='header'>
            <View margin="9px 0px 7px 32px">
                <AtoZMyHRLogo/>
            </View>
        </Row>
        <Row justifyContent='center' className="content-container">
            {!isMobile && 
                <Col width="100%" flex={1}>
                    <Spacer flex={1}/> 
                    <LeftImage/>
                </Col>
            }

            <Col width="100%" flex={2} alignItems='center'  >
                <Row alignItems='center' minHeight={"167px"}>
                    <Text textAlign='center' fontSize="40px" fontWeight="bold" lineHeight="52px">
                        Welcome to the A to Z | My HR Kiosk
                    </Text>
                </Row>
                
                <Row alignItems='center' >
                    <Card minWidth={'522px'} minHeight={ isMaask ? "320px" : "204px"}padding={'32px'}>
                        <Col gridGap={24} width="100%">
                            <Text textAlign='center' fontSize="20px" fontWeight="bold" lineHeight="32px" >
                                { isMaask ? 'Choose a log in method to begin' : 'Log in to A to Z to begin'}
                            </Text>
                            { isMaask && 
                                <LinkCard isElevated={true} href={getEnvironmentBasedRedirectionURL(LoginMethod.MAASK)}>
                                    <Col gridGap={4} flex={4}>
                                        <Text fontSize="20px" fontWeight='bold' lineHeight="32px">
                                            Log in with your Badge
                                        </Text>
                                        <Text fontSize="16px" fontWeight='regular' lineHeight="24px">
                                            Scan your badge and enter pin.
                                        </Text>
                                    </Col>
                                    <Col flex={1} alignItems='center' justifyContent='center'>
                                        <IconChevronRight size={32} aria-hidden={true}/>
                                    </Col>
                                </LinkCard>
                            }
                            <LinkCard isElevated={true} href={getEnvironmentBasedRedirectionURL(LoginMethod.ATOZ)}>
                                <Col gridGap={4} flex={4}>
                                    <Text fontSize="20px" fontWeight='bold' lineHeight="32px">
                                        {isMaask ? 'Log in with A to Z':'A to Z log in'}
                                    </Text>
                                    <Text fontSize="16px" fontWeight='regular' lineHeight="24px">
                                        Enter A to Z username and password.
                                    </Text>
                                </Col>
                                <Col flex={1} alignItems='center' justifyContent='center'>
                                    <IconChevronRight size={32} aria-hidden={true}/>
                                </Col>
                            </LinkCard>
                        </Col>    
                    </Card>                            
                </Row>
                <Row  alignItems='center'  minHeight={"180px"} padding="0px 108px 0px 108px" gridGap={'24px'}>
                        <Text fontSize={'20px'}>
                            { isMaask ? `Don't remember your MAASK pin? Scan the QR code to access the A to Z | My HR Kiosk on your phone.` : `Don't remember your password? Scan the QR code to access the A to Z | My HR Kiosk on your phone.`}
                        </Text>
                        <img
                            className='qr-code'
                            role="presentation"
                            height="92px"
                            width="92px"
                            alt={"QRcode"}
                            src={getEnvironmentBasedURL('QRCode')}
                        />
                    </Row>
            </Col>

            {!isMobile &&
                <Col width="100%" alignItems='flex-end' flex={1}>
                    <Spacer flex={1}/> 
                    <RightImage/>
                </Col>
            }
        </Row>
    </View>
  );
};
